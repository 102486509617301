import "vanilla-cookieconsent";

window.CC = window.initCookieConsent();
window.CC.run({
  autorun: true,
  delay: 0,
  current_lang: "en",
  autoclear_cookies: true,
  cookie_expiration: 365,
  page_scripts: true,
  onAccept: function (cookies) {},
  languages: {
    en: {
      consent_modal: {
        title: "Our Cookies",
        description:
          'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only upon your approval. <a aria-label="Cookie policy" class="cc-link" href="https://www.firstbluehealthcare.co.uk/privacy-policy">Our privacy policy</a>',
        primary_btn: {
          text: "Accept",
          role: "accept_all", //'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: "Settings",
          role: "settings", //'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: "Cookie preferences",
        save_settings_btn: "Save settings",
        accept_all_btn: "Accept all",
        cookie_table_headers: [
          { col1: "Name" },
          { col2: "Domain" },
          { col3: "Expiration" },
          { col4: "Description" },
          { col5: "Type" },
        ],
        blocks: [
          {
            title: "Cookie usage",
            description:
              "We use cookies, which are small text files, to improve your experience on our website and to show you personalised content. You can accept all or manage them individually.",
          },
          {
            title: "Strictly necessary cookies",
            description:
              "These cookies are essential for the proper functioning of this website. Without these cookies, the website would not work properly.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true,
            },
          },
          {
            title: "Analytics cookies",
            description:
              "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.",
            toggle: {
              value: "analytics",
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              {
                col1: "_ga",
                col2: "google.com",
                col3: "2 years",
                col4: "Used to distinguish users.",
                col5: "Permanent cookie",
              },
              {
                col1: "_gid",
                col2: "google.com",
                col3: "1 day",
                col4: "Used to distinguish users.",
                col5: "Permanent cookie",
              },
              {
                col1: "_ga_<id>",
                col2: "google.com",
                col3: "2 years",
                col4: "Used to persist session state.",
                col5: "Permanent cookie",
              },
              {
                col1: "_gac_gb_<id>",
                col2: "google.com",
                col3: "90 days",
                col4: "Campaign related information.",
                col5: "Permanent cookie",
              },
            ],
          },
          {
            title: "More information",
            description:
              'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="https://www.firstbluehealthcare.co.uk/contact-us">contact us</a>.',
          },
        ],
      },
    },
  },
});
